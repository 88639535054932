import React, { useState, useContext } from 'react';
import './ServicesSection.css';
import { LanguageContext } from './LanguageContext';

const ServicesSection = () => {
  const { language } = useContext(LanguageContext);
  const [activeIndex, setActiveIndex] = useState(null);

  const services = [
    {
      title: {
        en: 'Web Development',
        es: 'Desarrollo de Sitios Web',
      },
      description: {
        en: 'Custom, professional web development to grow your business.',
        es: 'Desarrollo personalizado y profesional de sitios web para impulsar tu negocio.',
      },
      details: {
        en: 'We offer web development solutions tailored to your needs, whether it’s an informative site, a blog, an online store, or a complete web application. We work with modern technologies to ensure your site is fast, secure, and attractive.',
        es: 'Ofrecemos soluciones de desarrollo web que se adaptan a tus necesidades, ya sea un sitio informativo, un blog, una tienda en línea o una aplicación web completa. Trabajamos con tecnologías modernas para asegurar que tu sitio sea rápido, seguro y atractivo.',
      },
      image: '/images/webdev.png',
    },
    {
      title: {
        en: 'Search Engine Optimization (SEO)',
        es: 'Optimización SEO',
      },
      description: {
        en: 'Improving your website’s visibility on search engines.',
        es: 'Mejorando la visibilidad de tu sitio web en los motores de búsqueda.',
      },
      details: {
        en: 'SEO optimization is essential for your website to appear in the top search results. We focus on optimizing content, improving loading speed, and following best practices to ensure your site has the best chance of ranking well.',
        es: 'La optimización SEO es fundamental para que tu sitio web aparezca en los primeros resultados de búsqueda. Nos enfocamos en optimizar el contenido, mejorar la velocidad de carga y seguir las mejores prácticas para asegurar que tu sitio tenga la mejor oportunidad de posicionarse bien en los motores de búsqueda.',
      },
      image: '/images/seo.png',
    },
    {
      title: {
        en: 'Social Media Marketing',
        es: 'Marketing en Redes Sociales',
      },
      description: {
        en: 'Strategies to connect with your audience on social platforms.',
        es: 'Estrategias para conectar con tu audiencia en plataformas sociales.',
      },
      details: {
        en: 'Social media marketing helps you effectively connect with your audience. We create tailored strategies for each platform, boosting engagement and increasing your brand’s visibility through engaging and relevant content.',
        es: 'El marketing en redes sociales te ayuda a conectar con tu audiencia de manera efectiva. Creamos estrategias personalizadas para cada red social, impulsando la participación y aumentando la visibilidad de tu marca mediante contenido atractivo y relevante.',
      },
      image: '/images/social.png',
    },
  ];

  const handleCardClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="services-section">
      <h2>{language === 'en' ? 'SERVICES' : 'SERVICIOS'}</h2>
      <h4 className='mb-5'>{language === 'en' ? '(Click for more information)' : '(Haz click para mas informacion)'}</h4>
      <div className="services-grid">
        {services.map((service, index) => (
          <div
            key={index}
            className="service-card"
            onClick={() => handleCardClick(index)}
          >
            <div
              className="card-image"
              style={{ backgroundImage: `url(${service.image})` }}
            >
              {activeIndex !== index && (
                <div className="card-title-overlay">
                  <h3 className="card-title">{service.title[language]}</h3>
                </div>
              )}
            </div>
            {activeIndex === index && (
              <div className="card-content">
                <p className="card-description">{service.description[language]}</p>
                <p className="card-description">{language === 'en' ? 'Social Media Marketing' : 'Marketing en Redes Sociales'}</p>
              </div>
            )}
            {activeIndex === index && (
              <div className="card-details">
                <p>{service.details[language]}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServicesSection;
