import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LanguageProvider } from './components/LanguageContext';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import ContactPage from './components/ContactPage';
import Footer from './components/Footer';
import './App.css';
import BlogPage from './components/BlogPage';
import BlogPost from './components/BlogPost';
import { AuthProvider } from './contexts/AuthContext';
import { Element } from 'react-scroll';

function App() {
  return (
    <AuthProvider>
      <LanguageProvider>
        <Router>
          <div className="App">
            <Navbar />
            <main>
              <Routes>
                <Route path="/" element={<Element name="home"><HomePage /></Element>} />
                <Route path="/about" element={<Element name="about"><AboutPage /></Element>} />
                <Route path="/contact" element={<Element name="contact"><ContactPage /></Element>} />
                <Route path="/blog" element={<Element name="blog"><BlogPage /></Element>} />
                <Route path="/blog/:slug" element={<BlogPost />} />
              </Routes>
            </main>
            <Footer />
          </div>
        </Router>
      </LanguageProvider>
    </AuthProvider>
  );
}

export default App;