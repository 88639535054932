import React, { useContext } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { LanguageContext } from './LanguageContext';
import Marquee from './Marquee';
import blogData from '../data/blog.json';
import './BlogPost.css';
import Comments from './Comments';

const BlogPost = () => {
  const { slug } = useParams();
  const { language } = useContext(LanguageContext);
  
  const post = blogData.published.find(post => post.slug === slug);

  if (!post) return <Navigate to="/blog" />;

  const renderContent = (content) => {
    return content.map((section, index) => {
      switch (section.type) {
        case 'paragraph':
          return <p key={index} className="post-paragraph">{renderTextWithLinks(section.text)}</p>;
        case 'heading':
          return <h2 key={index} className="post-heading">{section.text}</h2>;
        case 'list':
          return (
            <ul key={index} className="post-list">
              {section.items.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          );
        case 'image':
          return (
            <div key={index} className="post-image-container">
              <img 
                src={section.url} 
                alt={section.caption} 
                className="post-image" 
              />
              {section.caption && (
                <p className="post-image-caption">{section.caption}</p>
              )}
            </div>
          );
        default:
          return null;
      }
    });
  };

  const renderTextWithLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);
    return parts.map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <div className="blog-post-page">
      <div className="post-header-image">
        <img src={post.headerImage} alt={post.title[language]} />
        <div className="post-header-overlay">
          <span className="post-header-date">{post.date}</span>
          <h1 className="post-header-title">{post.title[language]}</h1>
        </div>
      </div>

      <Marquee 
        text={language === 'en' ? 'blog ' : 'blog '}
        bgColor="black"
        textColor="#f16f75"
      />

      <div className="post-content-wrapper">
        <div className="post-content">
          <div className="post-body">
            {renderContent(post.content[language])}
          </div>
          <div className="post-closing">
            <span>Se cuidan, B</span>
            <img src="/images/bee.png" alt="Bee" className="bee-icon" />
          </div>
        </div>
      </div>
      
      <Comments postId={slug} />
    </div>
  );
};

export default BlogPost; 