import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from './LanguageContext';
import './Navbar.css';

const Navbar = () => {
  const { language, toggleLanguage } = useContext(LanguageContext);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="nav-content">
        <Link to="/" className="logo-container">
          <img src="/images/logo.png" alt="Logo" className="logo" />
        </Link>

        <Link to="/" className="logo-text">Xochipilli Design</Link>

        <div className="nav-links">
          <ul>
            <li>
              <Link to="/about" className="nav-link">
                {language === 'es' ? 'acerca de' : 'about'}
              </Link>
            </li>
            <li>
              <Link to="/blog" className="nav-link">
                {language === 'es' ? 'blog' : 'blog'}
              </Link>
            </li>
            <li>
              <Link to="/contact" className="nav-link">
                {language === 'es' ? 'contacto' : 'contact'}
              </Link>
            </li>
          </ul>
        </div>

        <div className="language-toggle-container">
          <button onClick={toggleLanguage} className="language-toggle">
            {language === 'es' ? 'english' : 'español'}
          </button>
        </div>

        <button className="hamburger" onClick={handleMenuToggle}>
          ☰
        </button>
      </div>

      {isMenuOpen && (
        <div className="nav-links-mobile">
          <ul>
            <li>
              <Link to="/about" className="nav-link">
                {language === 'es' ? 'acerca de' : 'about'}
              </Link>
            </li>
            <li>
              <Link to="/blog" className="nav-link">
                {language === 'es' ? 'blog' : 'blog'}
              </Link>
            </li>
            <li>
              <Link to="/contact" className="nav-link">
                {language === 'es' ? 'contacto' : 'contact'}
              </Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
