import React, { useState, useContext } from 'react';
// import { motion } from 'framer-motion';
import emailjs from 'emailjs-com';
import { LanguageContext } from './LanguageContext';
import Marquee from './Marquee';
import './ContactPage.css';

const ContactPage = () => {
  const { language } = useContext(LanguageContext);

  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [submissionStatus, setSubmissionStatus] = useState({
    status: null,
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmissionStatus({ status: null, message: '' });

    emailjs
      .sendForm(
        'service_sngu1cv',
        'template_mr4qbva',
        e.target,
        'FbNZMrH0eoRvS5WFT'
      )
      .then(
        (result) => {
          console.log('Message Sent:', result.text);
          setSubmissionStatus({
            status: 'success', 
            message: language === 'en' 
              ? 'Message sent successfully!' 
              : '¡Mensaje enviado con éxito!'
          });
          setFormState({ name: '', email: '', message: '' });
        },
        (error) => {
          console.log('Message Not Sent:', error.text);
          setSubmissionStatus({
            status: 'error', 
            message: language === 'en' 
              ? 'Failed to send message. Please try again.' 
              : 'No se pudo enviar el mensaje. Por favor, inténtelo de nuevo.'
          });
        }
      );
  };

  return (
    <div className="contact-page">
      <div className="banner-image">
        <img src="/images/contact.png" alt="Contact" className="banner-img" />
      </div>

      <Marquee 
        text={language === 'en' ? 'contact ' : 'contacto '}
        bgColor="black"
        textColor="#f16f75"
      />

      <div className="contact-info-container">
        <h2 className="contact-header">
          {language === 'en' ? 'FOR CONTACTING ME' : 'PARA CONTACTARME'}
        </h2>
        <div className="contact-info">
          <a className="contact-link" href="tel:15414108786">
            📞 {language === 'en' ? 'My number:' : 'Mi número:'} <span className="highlight">(541) 410-8786</span>
          </a>
          <a className="contact-link" href="mailto:hello@xochipillidesign.com">
            📧 {language === 'en' ? 'My email address:' : 'Mi correo electrónico:'} <span className="highlight">hello@xochipillidesign.com</span>
          </a>
        </div>
      </div>

      <div className="contact-form-container">
        <h3 className="contact-header-2">
          {language === 'en' ? 'FOR ME TO CONTACT YOU' : 'PARA QUE YO LE CONTACTE'}
        </h3>
        
        <form className="contact-form" onSubmit={handleSubmit}>
          <label className="form-label" htmlFor="name">
            {language === 'en' ? 'name' : 'nombre'}
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formState.name}
            onChange={handleInputChange}
            placeholder={language === 'en' ? 'Your Name' : 'Su Nombre'}
            className="form-input"
          />

          <label className="form-label" htmlFor="email">
            {language === 'en' ? 'email address' : 'correo electrónico'}
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formState.email}
            onChange={handleInputChange}
            placeholder={language === 'en' ? 'example@example.com' : 'ejemplo@ejemplo.com'}
            className="form-input"
          />

          <label className="form-label" htmlFor="message">
            {language === 'en' ? 'message' : 'mensaje'}
          </label>
          <textarea
            id="message"
            name="message"
            value={formState.message}
            onChange={handleInputChange}
            placeholder={language === 'en' ? 'What can I help you with?' : '¿En qué puedo ayudarle?'}
            className="form-textarea"
          ></textarea>

          <button type="submit" className="submit-button">
            {language === 'en' ? 'send' : 'enviar'}
          </button>

          {submissionStatus.status && (
            <div 
              className={`submission-message ${
                submissionStatus.status === 'success' 
                  ? 'success-message' 
                  : 'error-message'
              }`}
            >
              {submissionStatus.message}
            </div>
          )}
        </form>
      </div>

    </div>
  );
};

export default ContactPage;
