import React, { useState, useContext } from 'react';
import { motion } from 'framer-motion';
import projectData from '../data/projects.json';
import { LanguageContext } from './LanguageContext';
import Marquee from './Marquee';
import ServicesSection from './ServicesSection';
import './AboutPage.css';

const AboutPage = () => {
  const { language } = useContext(LanguageContext);
  const [currentProject, setCurrentProject] = useState(0);

  const nextProject = () => {
    setCurrentProject((prev) => (prev + 1) % projectData.length);
  };

  const prevProject = () => {
    setCurrentProject((prev) => (prev - 1 + projectData.length) % projectData.length);
  };

  return (
    <section className="about-page">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="banner-image">
          <img
            src="/images/about.png"
            alt="About Banner"
            className="banner-img"
          />
        </div>

      <Marquee 
        text={language === 'en' ? 'about ' : 'acerca de '}
        bgColor="black"
        textColor="#f16f75"
      />

      <div>
        <ServicesSection className="about-header"/>
      </div>

      
      <section class="pricing-section">
        <h2>{language === 'en' ? 'PRICING' : 'PRECIOS'}</h2>
        
        <div class="pricing-container">
          <div class="web-pricing">
            <div class="web-pricing-card">
              <h4>{language === 'en' ? 'Website Package' : 'Paquete de Sitio Web'}</h4>
              <div class="price-container">
                <div class="setup-price">
                  <span class="price-amount">$300</span>
                  <span class="price-label">{language === 'en' ? 'setup fee' : 'tarifa de instalación'}</span>
                </div>
                <span class="price-plus">+</span>
                <div class="monthly-price">
                  <span class="price-amount">$30</span>
                  <span class="price-label">{language === 'en' ? '/month' : '/mes'}</span>
                </div>
              </div>
              <div class="pricing-details">
                <h5>{language === 'en' ? "What's Included:" : '¿Qué está incluido?'}</h5>
                <ul>
                  <li>{language === 'en' ? 'One-page responsive website' : 'Sitio web responsivo de una página'}</li>
                  <li>{language === 'en' ? 'Basic SEO setup' : 'Configuración básica de SEO'}</li>
                  <li>{language === 'en' ? 'Contact form' : 'Formulario de contacto'}</li>
                  <li>{language === 'en' ? 'Online ordering integration for restaurants' : 'Integración de pedidos en línea para restaurantes'}</li>
                  <li>{language === 'en' ? 'Regular maintenance & hosting' : 'Mantenimiento regular y alojamiento'}</li>
                  <li>{language === 'en' ? 'Minor updates (text/image changes and add-ons)' : 'Actualizaciones menores (cambios de texto/imágenes y complementos)'}</li>
                </ul>
                <div className="additional-pages">
                  <p>{language === 'en' ? 'Need more pages?' : '¿Necesitas más páginas?'}</p>
                  <p>{language === 'en' ? 'Additional pages: $100 each added to setup fee' : 'Páginas adicionales: $100 cada una añadida a la tarifa de instalación'}</p>
              </div>
              </div>
            </div>
          </div>

          <h3>{language === 'en' ? 'Social Media Management' : 'Gestión de Redes Sociales'}</h3>
            <div class="add-ons-footer">
              <span class="discount-text">{language === 'en' ? '💫 Save 10% when combined with website package 💫' : '💫 Ahorra 10% al combinar con el paquete de sitio web 💫'}</span>
            </div>
          <div class="pricing-grid">
            <div class="pricing-card">
              <h4>{language === 'en' ? 'Basic' : 'Básico'}</h4>
              <div class="price">{language === 'en' ? '$50' : '$50'}<span>{language === 'en' ? '/month' : '/mes'}</span></div>
              <ul>
                <li>{language === 'en' ? '4 posts per month' : '4 publicaciones por mes'}</li>
                <li>{language === 'en' ? 'Basic text or image posts' : 'Publicaciones básicas de texto o imagen'}</li>
                <li>{language === 'en' ? 'Perfect for businesses starting with a minimal online presence' : 'Perfecto para negocios que comienzan con una presencia en línea mínima'}</li>
              </ul>
            </div>

            <div class="pricing-card">
              <h4>{language === 'en' ? 'Standard' : 'Estándar'}</h4>
              <div class="price">{language === 'en' ? '$100' : '$100'}<span>{language === 'en' ? '/month' : '/mes'}</span></div>
              <ul>
                <li>{language === 'en' ? '8 posts per month' : '8 publicaciones por mes'}</li>
                <li>{language === 'en' ? 'Basic text or image posts' : 'Publicaciones básicas de texto o imagen'}</li>
                <li>{language === 'en' ? 'Engagement support' : 'Soporte de participación'}</li>
                <li>{language === 'en' ? 'Ideal for businesses looking to grow their reach' : 'Ideal para negocios que buscan aumentar su alcance'}</li>
              </ul>
            </div>

            <div class="pricing-card">
              <h4>{language === 'en' ? 'Premium' : 'Premium'}</h4>
              <div class="price">{language === 'en' ? '$150' : '$150'}<span>{language === 'en' ? '/month' : '/mes'}</span></div>
              <ul>
                <li>{language === 'en' ? '12 posts per month' : '12 publicaciones por mes'}</li>
                <li>{language === 'en' ? 'Basic text or image posts' : 'Publicaciones básicas de texto o imagen'}</li>
                <li>{language === 'en' ? 'Monthly performance tracking' : 'Seguimiento mensual del rendimiento'}</li>
                <li>{language === 'en' ? 'Best for maximum visibility and engagement' : 'Mejor para máxima visibilidad y participación'}</li>
              </ul>
            </div>
          </div>

          <div class="add-ons-section">
            <div class="add-ons-container">
              <div class="add-ons-header">
                <h4>{language === 'en' ? 'Level Up Your Content' : 'Mejora Tu Contenido'}</h4>
              </div>
              
              <div class="add-ons-content">
                <div class="add-on-type">
                  <span class="add-on-label">{language === 'en' ? 'Video Posts' : 'Publicaciones de Video'}</span>
                  <span class="add-on-price">{language === 'en' ? 'Starting from $50' : 'A partir de $50'}</span>
                </div>
                
                <div class="add-on-divider"></div>
                
                <div class="add-on-type">
                  <span class="add-on-label">{language === 'en' ? 'Graphic-Designed Posts' : 'Publicaciones Diseñadas Gráficamente'}</span>
                  <span class="add-on-price">{language === 'en' ? 'Starting from $20' : 'A partir de $20'}</span>
                </div>
              </div>
              

            </div>
          </div>
        </div>
      </section>

      <h1 className="about-header">
        {language === 'en' ? 'PAST WORK' : 'TRABAJOS PASADOS'}
      </h1>

      <div className="project-carousel">
        <button className="carousel-button prev" onClick={prevProject}>←</button>

        {projectData && projectData.length > 0 && projectData[currentProject] && (
          <motion.img
            key={projectData[currentProject].name}
            src={projectData[currentProject].image}
            alt={projectData[currentProject].name}
            className="project-image"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          />
        )}

        <button className="carousel-button next" onClick={nextProject}>→</button>
      </div>

      <h3 className="project-name">
        {projectData[currentProject] && projectData[currentProject].name}
      </h3>

      <div className="tech-buttons">
        {projectData[currentProject] && projectData[currentProject].technologies.map((tech, index) => (
          <button key={index} className="tech-button">
            {tech}
          </button>
        ))}
      </div>

      <div className="view-site-container">
        <a
          href={projectData[currentProject] && projectData[currentProject].link}
          className="view-site-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          {language === 'en' ? '~ view site ~' : '~ ver sitio ~'}
        </a>
      </div>
    </motion.div>
  </section>
  )
}

export default AboutPage;
