import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LanguageContext } from './LanguageContext';
import Marquee from './Marquee';
import './BlogPage.css';
import blogData from '../data/blog.json';

const BlogCard = ({ post, language, isUpcoming = false }) => (
  <div className={`blog-card ${isUpcoming ? 'upcoming' : ''}`}>
    <div className="blog-card-image" style={{ backgroundImage: `url(${post.cardImage})` }}>
      <div className="blog-card-overlay">
        <h3 className="blog-card-title">{post.title[language]}</h3>
        {!isUpcoming && <span className="blog-card-date">{post.date}</span>}
        {isUpcoming && (
          <div className="upcoming-label">
            <span>{language === 'en' ? 'Coming Soon' : 'Próximamente'}</span>
          </div>
        )}
      </div>
    </div>
  </div>
);

const BlogPage = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div className="blog-page">
      <div className="banner-image">
        <img src="/images/blog.png" alt="Blog Banner" className="banner-img" />
      </div>

      <Marquee 
        text={language === 'en' ? 'blog ' : 'blog '}
        bgColor="black"
        textColor="#f16f75"
      />

      <div className="blog-container">
        <section className="published-posts">
          <h2>{language === 'en' ? 'PUBLISHED POSTS' : 'PUBLICACIONES'}</h2>
          <div className="blog-grid">
            {blogData.published.map((post, index) => (
              <Link to={`/blog/${post.slug}`} key={index} className="blog-card-link">
                <BlogCard post={post} language={language} />
              </Link>
            ))}
          </div>
        </section>

        <section className="upcoming-posts">
          <h2>{language === 'en' ? 'COMING SOON' : 'PRÓXIMAMENTE'}</h2>
          <div className="blog-grid">
            {blogData.upcoming.map((post, index) => (
              <BlogCard key={index} post={post} language={language} isUpcoming={true} />
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default BlogPage; 