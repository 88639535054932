import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { db } from '../firebase/config';
import { 
  collection, 
  addDoc, 
  query, 
  orderBy, 
  onSnapshot,
  serverTimestamp 
} from 'firebase/firestore';
import './Comments.css';

const Comments = ({ postId }) => {
  const { user, login, logout } = useContext(AuthContext);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Fetch comments
  useEffect(() => {
    const commentsRef = collection(db, 'posts', postId, 'comments');
    const q = query(commentsRef, orderBy('createdAt', 'desc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const commentsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setComments(commentsData);
    });

    return () => unsubscribe();
  }, [postId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newComment.trim() || !user || isSubmitting) return;

    setIsSubmitting(true);
    try {
      await addDoc(collection(db, 'posts', postId, 'comments'), {
        text: newComment,
        userId: user.uid,
        userName: user.displayName,
        userPhoto: user.photoURL,
        createdAt: serverTimestamp()
      });
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    return new Date(timestamp.seconds * 1000).toLocaleDateString();
  };

  // Example of adding a comment
  const addComment = async (commentText) => {
    try {
      await addDoc(collection(db, 'comments'), {
        text: commentText,
        createdAt: new Date()
      });
    } catch (error) {
      console.error("Error adding comment: ", error);
    }
  };

  return (
    <div className="comments-section">
      <div className="comments-container">
        <h2 className="comments-title">comments</h2>
        
        <div className="auth-section">
          {user ? (
            <div className="user-info">
              <img src={user.photoURL} alt={user.displayName} className="user-avatar" />
              <span>Hello, {user.displayName}</span>
              <button onClick={logout} className="auth-button">Logout</button>
            </div>
          ) : (
            <button onClick={login} className="auth-button">Login with Google</button>
          )}
        </div>

        {user && (
          <div className="comment-form-container">
            <div className="comment-avatar">
              <img src={user.photoURL} alt={user.displayName} />
            </div>
            <form onSubmit={handleSubmit} className="comment-form">
              <input
                type="text"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="Got something to add to the conversation? Add it here!"
                className="comment-input"
                disabled={isSubmitting}
              />
            </form>
          </div>
        )}

        <div className="comments-list">
          {comments.map(comment => (
            <div key={comment.id} className="comment">
              <div className="comment-avatar">
                <img src={comment.userPhoto} alt={comment.userName} />
              </div>
              <div className="comment-content">
                <div className="comment-header">
                  <span className="comment-author">{comment.userName}</span>
                  <span className="comment-date">{formatDate(comment.createdAt)}</span>
                </div>
                <p className="comment-text">{comment.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Comments; 